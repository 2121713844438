* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
    border: medium none;
    margin: 0;
    padding: 0;
    font-family: "Syne", sans-serif;
}

:focus {
    outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}


h3 {
    font-size: 24px;
}

p {}

h4 {
    font-size: 18px;
}

img,
fieldset {
    border: 0 none;
}

a {
    border: none;
    outline: none;
}

input[type="submit"],
select {
    -webkit-appearance: none;
}

img {

    border: none;
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
}

iframe {
    vertical-align: middle;
}

a:hover {
    text-decoration: none;
    color: #002395;
}

.clear {
    clear: both;
}

ul::after {
    clear: both;
    content: "";
    display: block;
}

ul {
    list-style: none;
}

body {
    font-family: "Syne", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 25px;
    color: #343B52;
    background: #F5F5F5;
}

header {
    background: linear-gradient(125deg, #8569d0 30%, #7578e6 70%);
}

.top-header {
    padding: 5px;
    text-align: center;
}

.top-header p {
    font-weight: 500;
    font-size: 15px;
}

header .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255) !important;
    font-size: 15px;
    margin: 0 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.call-to-action {
    display: flex;
    padding: 0 0 0 50px;
}

.sign-in-btn img {
    width: 28px;
    margin-left: 7px;
}

.sign-in-btn {
    border-radius: 8px;
    border: 1px solid #FFF;
    padding: 5px 7px 5px 12px !important;
    display: block;
    width: fit-content;
    text-align: center;
    margin-left: 42px;
    display: flex;
    white-space: nowrap;
    align-items: center;
}

header .navbar-brand img {
    width: 129px;
}

.hero-section {
    background: url("../images/hero-bg.png") no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 0 0;
    background-color: #efeffb;
}

/* .feature-img-section{
    background: url("../images/hero-banner.png") no-repeat;
    background-size: cover;
} */


.hero-content-section h3 {
    font-size: 26.145px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 10.458px;
    text-transform: uppercase;
}

.hero-content-section h1 {
    /* color: #353535; */
    text-align: left;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 22px;
}


.hero-content-section h5 {
    color: #282828;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}



.hero-content-section img {
    margin-right: 12px;
}

.hero-content-section .get-started-btn {
    margin-top: 33px;
}

.hero-section .swiper-button-prev,
.hero-section .swiper-button-next {
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #8569d0;
    width: 22px !important;
    height: 22px !important;
    padding: 26px !important;
}

.side-padding {
    padding: 0 122px;
}

.hero-section .swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 20px;
    color: #8569d0;
}

.help-box {
    border-radius: 19px;
    background: #FFF;
    padding: 52px 92px 52px 92px;
    text-align: center;
    position: relative;
    color: #fff;
    height: 100%;
}

.help-box h2 {
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.44px;
    margin-bottom: 15px;
}

.text-upper {
    color: #282828;
    text-align: center;
    font-size: 16.667px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.help-box p {
    color: #fff !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.inner-help-box {
    display: flex;
    align-items: flex-start;
    padding: 32px;
}

.inner-help-box-main {
    text-align: left;
}

.text-left {
    text-align: left !important;
}

.robot-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
}

.inner-help-box-main h4 {
    font-size: 17px;
    font-weight: 700;
}

.inner-help-box-main p {
    font-size: 12px;
}

.inner-help-box img {
    margin-right: 15px;
}

.businesses-section {
    padding: 140px 0;
}

.navbar-toggler:focus {
    box-shadow: unset !important;
}

.help-ouer-section {
    padding: 40px 0;
}

.main-heading {
    color: #282828;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.businesses-section .main-heading {
    margin-bottom: 62px;
}

.businesses-logo {
    padding: 32px;
    background: #fff;
    margin-bottom: 32px;
}

.main-subheading {
    color: #282828;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.588px;
    margin-top: 18px;
}

.quickstart-card {
    padding: 15px;
    border-radius: 6px;
    margin-top: 9px;
    background: #f3f6f8;
    cursor: pointer;
    height: 100%;
}

.quickstart-card:hover {
    background: #6d50ff4f;
}

.quickstart-card h6 {
    margin: 0 0 4px 0;
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.quickstart-card.active {
    background: #6d50ff4f;
}

.testimonial-section,
.missed-section {
    padding-bottom: 0;
    padding-top: 132px;
}

.client-box {
    border-radius: 13.965px;
    background: #ffffff14;
    padding: 32px;
    position: relative;
    height: 100%;
    color: #fff;
    --tw-backdrop-blur: blur(8px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.inner-client-box {
    display: flex;
    align-items: center;
}

.inner-client-box div p {
    font-size: 14px;
}

.inner-client-box div {
    margin-left: 12px;
}

.quote-box {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
}

.outer-testimonial-box {
    margin-top: 42px;
}

.missed-card-box {
    border-radius: 20px 20px 0 0;
    background: #FFF;
    padding: 62px 32px;
    height: 100%;
}

.missed-card-box.gray {
    background: #E1E9EB;
}

.missed-card-box.orange {
    background: #F9F2E8;
}

.missed-card-box.red {
    background: #E8EAF6;
}

.missed-card-box img {
    height: 112px;
    display: block;
    margin-top: 42px !important;
}

.missed-card-box h3 {
    color: #282828;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 44.857px;
    letter-spacing: -1.2px;
    /* background: #ffffff; */
    display: inline-block;
    padding: 0;
    border-radius: 52px;
}

.read-more,
.read-more:hover {
    color: #655dd6;
    font-size: 16px;
    display: inline-block;
    margin: 12px 0 0 0;
    text-decoration: none;
    font-weight: 600;
}

.upper-heading {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    background: #7578e62e;
    display: block;
    padding: 11px 12px;
    border-radius: 8px;
    margin: 10px auto;
    width: fit-content;
}

.missed-section {
    background: url("../images/saas-landing-hero-overly.png") no-repeat;
    background-size: cover;
}

.help-box {
    background: url("../images/wave-bg.png") no-repeat;
    background-size: cover;
}

.nmatched-talent-section {
    background: url("../images/wave-bg.png") no-repeat;
    background-size: cover;
}



.how-works-section {
    padding: 62px 0;
    background: url("../images/hero-bg.png") no-repeat;
    background-size: cover;
    background-color: #efeffb;
}

.how-work-content h3 {
    color: #282828;
    font-size: 47px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -2.1px;
    margin-bottom: 32px;
}

.how-work-content h4 {
    color: #696969;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.643px;
    margin-bottom: 19px;
}

.steps-iiner-round {
    background: #7578e6;
    width: 42px;
    min-width: 42px;
    display: inline-block;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    color: #000;
    font-size: 22px !important;
    font-weight: 700;
    color: #fff !important;
    z-index: 1;
}

.steps-box {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    position: relative;
}

.background-differ.another-variant.row {
    background: #655dd60a;
}

.background-differ.row {
    background: #fff;
    border-radius: 22px;
    padding: 62px;
}

.steps-box span {
    color: #393939;
    font-size: 18px;
    margin-right: 19px;
    font-weight: 600;
}

.faq-section {
    padding: 90px 0;
}

.faq-section .accordion-item:first-of-type .accordion-button {
    padding: 0 !important;
    border: 1px solid;
}

.faq-section .accordion-button {
    padding: 0 !important;
    border: 1px solid;
}

.faq-section .last-child-case.accordion-header .accordion-button {
    border-bottom: 1px solid;
}

.number-faq {
    padding: 29px;
    border-right: 1px solid;
    width: 92px;
}

.faq-section .accordion-button span {
    margin-left: 12px;
}

.faq-section .accordion-button:focus {
    z-index: 3;
    border-color: #000 !important;
    outline: 0;
    box-shadow: unset !important;
}

.ready-box .main-heading {
    font-size: 32px;
}

.faq-section .accordion-item {
    background: transparent;
    border: none;
}

.faq-section .accordion-button {
    color: #000 !important;
    background-color: transparent !important;
    ;
    box-shadow: unset !important;
    font-size: 23px;
    font-weight: 700;
    border-radius: 22px !important;
    margin-bottom: 22px;
}

.faq-section .accordion-body {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    padding: 0 019px 12px;
    font-size: 17px;
    font-weight: 700;
    line-height: 32px;
    border: none;
    background: transparent;
}

.faq-section .accordion-button::after {
    right: 32px;
    position: absolute;
    filter: grayscale(1);
}

.footer-content h4 {
    color: #282828;
    font-size: 18.75px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 13px;
}

.footer-section {
    background: url("../images/footer-bg.png") no-repeat;
    padding: 32px 0;
    background-position: left;
}

.get-app-btn {
    border-radius: 115.833px;
    border: 1.158px solid #A3A3A3;
    padding: 13px;
    display: inline-block;
    margin-bottom: 9px;
    color: #6A6A6A;
    text-decoration: none;
    width: 100%;
    text-align: center;
}

.footer-link h6 {
    margin-bottom: 13px;
}

.footer-link ul li a {
    color: #6A6A6A;
    text-decoration: none;
}

.footer-link ul li {
    margin-bottom: 12px;
}

.footer-bottom {
    background: #282828;
    color: #fff;
    padding: 22px 0;
}

.btm-footer-content ul {
    display: flex;
    justify-content: flex-end;
}

.btm-footer-content ul li a {
    color: #fff;
    text-decoration: none;
    margin-left: 52px;
}

.ready-box {
    border-radius: 50px;
    background: url("../images/hero-bg.png") no-repeat;
    background-color: #efeffb;
    padding: 62px;
    text-align: center;
    background-size: contain;
}

.call-img {
    width: 64px;
    rotate: -8deg;
}

.get-started-btn.get-started-for-free.btn.btn-primary {
    margin-left: 12px;
    background: #7578e6;
    color: #fff;
    border: 1px solid;
}

.btn,
.swal-button,
.btn:hover,
.swal-button:hover .btn,
.swal-button,
.btn:hover,
.swal-button:hover {
    --color: #7d55d5;
    --color2: rgb(255, 255, 255);
    padding: 12px 15px 12px 22px;
    background-color: transparent;
    border-radius: 12px;
    border: .3px solid var(--color);
    transition: .5s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    font-weight: 700;
    font-size: 17px;
    color: var(--color);
}

.get-started-btn.btn.btn-primary img {
    margin-right: 0;
    margin-left: 6px;
    width: 24px;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #6b24d6;
    background-color: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
}

.btn::after,
.btn::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    transform: skew(90deg) translate(-50%, -50%);
    position: absolute;
    inset: 50%;
    left: 25%;
    z-index: -1;
    transition: .5s ease-out;
    background-color: var(--color);
}

.btn::before,
.swal-button::before {
    top: -50%;
    left: -25%;
    transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::before,
.swal-button.btn:hover::before {
    transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::after,
.swal-button.btn:hover::after {
    transform: skew(45deg) translate(-50%, -50%);
}

.btn:hover,
.swal-button:hover {
    color: var(--color2);
}

header .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(255 255 255) !important;
}

header .navbar-light .navbar-nav .nav-link.active {
    color: #fff !important;
}

.swal-button:not([disabled]):hover {
    background-color: transparent;
    color: #7d55d5;
}

.swiper-button-next,
.swiper-button-prev {
    background: #fff;
    padding: 32px;
    border-radius: 13px;
    color: #000;
}

.outer-testimonial-box .swiper {
    padding-bottom: 122px;
}

.outer-testimonial-box .swiper-button-prev:after,
.outer-testimonial-box .swiper-button-next:after {
    font-size: 24px;
}

.outer-testimonial-box .swiper-button-next,
.outer-testimonial-box .swiper-button-prev {
    top: unset;
    right: 44%;
    bottom: 20px;
}

.outer-testimonial-box .swiper-button-prev {
    left: 45%;
}

.faq-box .accordion {
    background: transparent;
}

header .navbar-light .navbar-toggler img {
    width: 24px;
}

header .navbar-light .navbar-toggler {
    color: rgb(255 255 255) !important;
    border-color: transparent;
}

header .navbar-light .navbar-toggler-icon {
    color: #fff;
    filter: brightness(0) invert(1);
}

.contact-form {
    box-shadow: 0px 5px 36px rgba(0, 0, 0, .06) !important;
    background: #fff;
    padding: 42px;
    border-radius: 21px;
}

.contact-form .form-label {
    font-size: 13px;
}

.contact-form .form-control,
.contact-form .form-select {
    padding: 14px 25px;
    border-radius: 32px;
    font-size: 13px;
}

.form-control:focus,
.form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: #7d55d5 !important;
    outline: 0;
    box-shadow: unset !important;
}

.feature-box {
    box-shadow: 0px 5px 36px rgba(0, 0, 0, .06) !important;
    background: #fff;
    padding: 40px;
    border-radius: 22px;
}

.feature-box h4 {
    font-weight: 600;
    margin-bottom: 11px;
}

.feature-content-section p span {
    color: #714bc6;
}

.feature-content-section h2 {
    font-size: 32px;
    margin-bottom: 22px;
}

.feature-box p {
    font-size: 14px;
}

.feature-content-section h5 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 15px;
}

.margin-top.row {
    margin-top: 62px;
}

.feature-inner-box h4 img {
    margin-bottom: 13px;
}

.feature-inner-box h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.feature-content-section p {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 10px;
    font-style: normal;
}

.contact-us-section {
    background: url("../images/shape-contact.png") no-repeat;
    padding: 52px 0;
    background-position: bottom;
}

span.error {
    font-size: 12px;
    color: red;
}

.shimmer {
    text-align: center;
    color: rgba(255, 255, 255, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #222;
}

@-moz-keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@-o-keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

.swal-text {
    color: rgb(0 0 0) !important;
    text-align: center;
}

.swal-modal {
    border-radius: 31px;
    padding: 32px;
}

.swal-footer {
    text-align: center;
}

.loader-area {
    background: #ffffffc9;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 19;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.contact-form .form-control,
.contact-form .form-select {
    padding: 14px 25px;
    border-radius: 32px;
    font-size: 13px;
}

.phone-number-text .react-tel-input .flag-dropdown {
    border: 0;
    border-radius: 23px 0 0 23px;
}

.phone-number-text .react-tel-input.form-control {
    padding: 0;
}

.phone-number-text .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 45px;
    line-height: 25px;
    height: 47px;
    width: 300px;
    outline: none;
    border: none;
}

.feature-section {
    background: url("../images/banner-feature.jpg") no-repeat;
    padding: 102px 0;
    background-position: left;
}

.feature-contant-section {
    padding: 12px 0 40px;
    background: url("../images/hero-bg.png") no-repeat;
    background-size: cover;
    background-color: #e9e9e973;
}

.feature-inner-box {
    padding: 15px;
    border: 1px solid #0000001a;
    border-radius: 15px;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border-left: 5px solid #6b24d647;
    background: #fff;
}

.feature-inner-box:hover {
    background: linear-gradient(125deg, #8569d07a 30%, #7578e682 70%);
    transform: scale(0.98);
    transition: .5s ease;
}

.feature-inner-box:hover h4,
.feature-inner-box:hover ul li {
    color: #ffff;
}

.feature-inner-box:hover .numb {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
    opacity: .3;
}

.feature-inner-box:hover:hover img {
    filter: brightness(0) invert(1);
}

.feature-inner-box ul li {
    line-height: normal;
    min-height: 185px;
    font-size: 17px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.numb {
    font-weight: 700;
    font-size: 65px;
    position: absolute;
    bottom: 7px;
    right: 5px;
    text-align: end;
    color: transparent;
    -webkit-text-stroke: 2px #8569d0;
    opacity: .2;
    font-family: 'Poppins', sans-serif;
}

.feature-inner-box ul {
    list-style: unset;
    margin-left: 0;
    font-size: 13px;
}

.testimonial-section .swiper-slide {
    height: unset;
}

.how-works-box {
    padding: 29px 72px;
    height: 121px;
    margin-bottom: 32px;
    text-align: center;
    border-radius: 22px;
    box-shadow: 0px 0px 12.813px 0px rgb(250 250 250 / 70%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../images/shape-contact.png") no-repeat;
    background-position: bottom;
    background-color: #fff;
    margin-bottom: 82px;
}

.industry-section .nav.nav-tabs {
    justify-content: center;
}

.industry-section .nav-tabs .nav-link.active {
    background-color: transparent;
    color: #7d55d5 !important;
    border-color: transparent;
}

.industry-section .nav-tabs .nav-link {
    font-size: 21px;
    color: #282828;
    font-weight: 700;
    padding: 11px 29px;

}

.industry-section .nav-tabs .nav-link:hover {
    color: #7d55d5 !important;
    border-color: transparent;
}

.industry-section {
    padding: 72px 0;
}

.tab-content {
    padding: 52px 0;
}

.left-industry-content p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 21px;
}

.left-industry-content h3 {
    color: #282828;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 19px;
}

.how-works-box h2 {
    font-size: 24px;
    font-weight: 700;
}

.common-padding {
    padding: 62px 0;
}

.terms-section h4 {
    font-size: 23px;
    font-weight: 700;
}

.terms-section ul {
    list-style: unset;
    margin-left: 19px;
}

.features-heading {
    margin-bottom: 12px;
}

.testimonial-section .main-heading {
    margin-bottom: 18px;
    color: #fff;
}

.testimonial-section .main-subheading {
    color: #fff;
}

.testimonial-section {
    border-radius: 142px 142px 0 0;
    padding-bottom: 50px;
    background: url("../images/businee.png") no-repeat;
    background-color: #7578e6;
}

.feature-section-home {
    background: url("../images/businee.png") no-repeat;
    background-size: cover;
    background-color: #ffffff;
    padding: 82px 0 0 0;
}

/* diksha */
.recordings-area {
    background: #f1f1f1;
    padding: 50px 0;
}

.react-audio-player.recording-audio {
    padding: 4px;
    border-radius: 29px !important;
    background: #f1f3f4;
    margin-top: 6px;
    border: 1px solid #7578e69c;
    box-shadow: 0px 5px 15px rgba(28, 34, 38, 0.20);
}

.recording-area-inner {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 18px;
    padding: 10px 15px;
    margin-bottom: 25px;
    border: 3px solid #fff;
    box-shadow: 0px 5px 15px rgba(28, 34, 38, 0.20);
    cursor: pointer;
}

.recording-area-inner:hover {
    border: 3px solid #7578e6;
}

.recordings-area-left {
    display: flex;
    align-items: center;
}

.recordings-area-left-content ul {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.recordings-area-right img {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    cursor: pointer;
}

.recordings-area-left img {
    width: 70px;
    min-width: 70px;
    height: 70px;
    min-height: 70px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    background: linear-gradient(45deg, #7578e6, transparent);
    padding: 3px;
}

.recordings-area-left-content h6 span {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: gray;
    margin-left: 5px;
}

.recordings-area-left-content ul li {
    background: #7578e624;
    font-size: 14px;
    color: #343b52;
    font-weight: 500;
    padding: 0 7px;
    border-radius: 4px;
    margin: 0 3px;
}

.recordings-area-left-content h6 {
    font-size: 16px;
    font-weight: 700;
    color: #343b52;
}

.ai-talent-content img {
    margin-right: 20px;
    width: 33px;
}

.under-talent-content h3 {
    font-size: 19px;
    font-weight: 700;
}

.ai-talent-content {
    display: flex;
    align-items: flex-start;
    padding-bottom: 30px;
    border-bottom: 1px solid #7578e657;
    margin-bottom: 30px;
}

.under-right-ai-talent {
    padding-left: 35px;
}

.ai-talent-section {
    background: linear-gradient(45deg, #ffffff, #ededed);
}

.customer-service .ready-box {
    display: flex;
    align-items: center;
    padding: 40px;
}

.customer-service-left img {
    width: 480px;
    min-width: 480px;
}

.customer-service {
    margin: 60px 0 0;
}

.customer-service-right {
    text-align: left;
    padding: 50px;
}

.customer-service-right h3 {
    font-size: 33px;
    font-weight: 700;
    line-height: 47px;
    margin-bottom: 10px;
}

.customer-service-right p {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 35px;
}

.unmatched-talent-section img {
    margin: 50px 0;
}

.under-right-ai-talent p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
    font-weight: 500;
}

.recordings-area-right {
    position: relative;
}

.telephone-show.nav-link {
    border: 1px solid #ffffff40;
    background: #7578e6;
    padding: 8px 13px !important;
    border-radius: 12px;
}
.get-started-details {
    box-shadow: 0px 5px 36px rgba(0, 0, 0, .06) !important;
    background: #fff;
    padding: 42px;
    border-radius: 21px;
}
.get-started-details form label {
    font-size: 13px;
    margin-bottom: 4px;
}
.get-started-details form input.form-control {
    border-radius: 32px;
    font-size: 13px;
    height: 49px;
}
.get-started-details .flag-dropdown, .get-started-details .react-tel-input .selected-flag:hover, .get-started-details .react-tel-input .selected-flag:focus, .get-started-details .react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 32px 0 0 32px!important;
}
.successfully-signed {
    box-shadow: 0px 5px 36px rgba(0, 0, 0, .06) !important;
    background: #fff;
    padding: 42px;
    border-radius: 21px;
}
.successfully-signed h4 a {
    color: #7974e0;
    font-weight: 700;
    font-size: 19px;
}
.successfully-signed h4 {
    font-size: 20px;
}
.sucessfully-signed-outer {
    min-height: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .react-audio-player.recording-audio {
    position: absolute;
    top: -7px;
    right: -2px;
    rotate: 180deg;
    opacity: 0;
} */
/* media-query */
@media (max-width: 1600px) {

    .hero-content-section h1,
    .help-box h2,
    .main-heading,
    .how-work-content h3 {
        font-size: 40px;
    }

    .help-box {
        padding: 52px 32px 52px 32px;
    }

    .testimonial-section .main-subheading {
        font-size: 17px;
    }
}

@media (max-width: 1400px) {
    .hero-content-section h5 {
        font-size: 15px;
    }

    .left-industry-content h3 {
        font-size: 25px;
    }

    .left-industry-content p {
        font-size: 15px;
    }

    .btn,
    .swal-button,
    .btn:hover,
    .swal-button:hover .btn,
    .swal-button,
    .btn:hover,
    .swal-button:hover {
        font-size: 15px;
    }

    .ready-box .main-heading {
        font-size: 28px;
        margin-bottom: 13px !important;
    }

    .faq-section .accordion-button {
        font-size: 20px;
    }

    .how-works-box h2 {
        font-size: 21px;
    }

    .industry-section .nav-tabs .nav-link {
        font-size: 16px;
        padding: 11px 15px;
    }

    .inner-client-box div {
        padding-right: 42px;
    }

    .call-img {
        width: 38px;
    }

    .main-heading {
        font-size: 32px;
        margin-bottom: 32px !important;
    }

    .main-subheading {
        font-size: 18px;
        line-height: unset;
    }

    .missed-card-box h3 {
        font-size: 22px;
        font-weight: 700;
    }

    .help-box h2 {
        /* color: #282828; */
        font-size: 30px;
        line-height: normal;
        margin-bottom: 15px;
        margin-top: 12px;
    }

    .missed-card-box {
        height: 100%;
    }

}

@media (max-width: 1200px) {

    .outer-testimonial-box .swiper-button-next,
    .outer-testimonial-box .swiper-button-prev {
        right: 43%;
    }

    .missed-card-box h3 {
        line-height: normal;
    }

    .side-padding {
        padding: 0 32px;
    }

    .inner-client-box div h5 {
        font-size: 16px;
    }

    .outer-testimonial-box .swiper-button-next,
    .outer-testimonial-box .swiper-button-prev {
        right: 43%;
    }

    .hero-content-section h1,
    .help-box h2,
    .main-heading,
    .how-work-content h3 {
        font-size: 30px;
    }

    .btn,
    .swal-button,
    .btn:hover,
    .swal-button:hover .btn,
    .swal-button,
    .btn:hover,
    .swal-button:hover {
        font-size: 12px;
    }

    .outer-testimonial-box .swiper-button-prev {
        left: 44%;
    }

    .hero-content-section {
        padding: 0;
    }

    .how-work-content h4 {
        font-size: 19px;
    }

    .footer-content h4 {
        font-size: 20.75px;
    }

}

@media (max-width: 1024px) {
    .inner-help-box {
        padding: 15px;
    }

    .how-works-box {
        padding: 29px 22px;
    }

    .missed-card-box {
        padding: 22px 32px;
    }


    .outer-testimonial-box .swiper-button-next,
    .outer-testimonial-box .swiper-button-prev {
        right: 42%;
    }

    .outer-testimonial-box .swiper-button-prev {
        left: 43%;
    }

    .help-box p {
        margin-bottom: 26px;
    }

    .missed-card-box h3 {
        font-size: 21px;
        line-height: unset;
        margin-top: 12px;
    }

    .missed-card-box img {
        width: 26%;
    }

    .footer-link ul li a {
        font-size: 12px;
    }

    .footer-content h4 {
        font-size: 17.75px;
    }

    h5 {
        font-size: 16px;
    }

    .btm-footer-content ul li a {
        margin-left: 30px;
        font-size: 12px;
    }

    .btm-footer-content p {
        font-size: 12px;
    }

    .footer-section {
        padding: 42px 0;
    }

    .faq-section .accordion-body {
        font-size: 13px;
        padding: 16px;
    }

    .how-work-img img {
        width: 82%;
    }

    .steps-box span {
        font-size: 15px;
    }
}

@media (max-width: 991px) {

    .feature-box {
        margin-top: 12px;
    }

    .telephone-show.nav-link {
        width: fit-content;
        background: transparent !important;
        border: none !important;
        padding: 6px 0 !important;
    }

    header .navbar-light .navbar-nav .nav-link {
        margin: 0;
    }

    .left-industry-content p {
        font-size: 13px;
    }

    .contact-us-section {
        padding: 42px 0;
    }

    .outer-testimonial-box .swiper-button-next,
    .outer-testimonial-box .swiper-button-prev {
        right: 40%;
    }

    .missed-card-box {
        border-radius: 20px;
    }

    .faq-section .accordion-body {
        font-size: 17px;
    }

    .outer-testimonial-box .swiper-button-prev {
        left: 39%;
    }

    .hero-content-section h1,
    .help-box h2,
    .main-heading,
    .how-work-content h3 {
        font-size: 25px;
    }

    .hero-content-section .get-started-btn {
        margin-top: 26px;
        padding: 7px 18px;
        font-size: 13px;
    }

    .sign-in-btn {
        margin-bottom: 11px !important;
        margin-left: 0 !important;
        margin-top: 9px !important;
    }

    .robot-img {
        width: 15%;
    }

    .businesses-logo {
        padding: 30px;
        margin-bottom: 12px;
    }

    .missed-card-box {
        margin-bottom: 16px;
    }

    .missed-section {
        padding-bottom: 52px;
    }

    .footer-link {
        margin-top: 28px;
    }

    .how-works-box {
        margin-bottom: 19px;
    }

    .how-work-img {
        text-align: center;
        margin-bottom: 13px;
    }

    .steps-box {
        margin-bottom: 15px;
    }

    .how-work-content {
        margin-bottom: 22px;
    }

    .testimonial-section,
    .missed-section {
        padding-top: 92px;
    }
}

@media (max-width: 767px) {
    .hero-content-section h3 {
        font-size: 17.145px;
        letter-spacing: 4.458px;
    }

    .hero-img {
        margin-top: 42px;
    }

    .feature-content-section h2 {
        font-size: 26px;
        margin-top: 22px;
    }

    .feature-content-section p {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .feature-content-section h5 {
        font-size: 18px;
    }

    .feature-content-section h2 {
        font-size: 19px;
    }

    .feature-content-section h5 {
        font-size: 14px;
    }

    .feature-content-section p {
        font-size: 13px;
        margin-bottom: -1px;
    }

    .feature-inner-box ul li {
        font-size: 14px;
    }

    .hero-content-section h1 {
        text-align: center !important;
    }

    .hero-content-section {
        text-align: center;
    }

    .how-works-box {
        height: unset;
    }

    .outer-testimonial-box .swiper-button-next,
    .outer-testimonial-box .swiper-button-prev {
        right: 41%;
    }

    .swiper-button-next,
    .swiper-button-prev {
        padding: 28px;
    }

    .outer-testimonial-box .swiper-button-prev {
        left: 36%;
    }

    .hero-content-section h5 {
        font-size: 13px;
        margin-top: 13px;
    }

    .btn {
        font-size: 14px;
    }

    .help-box p {
        margin-bottom: 6px;
    }

    .help-box {
        height: unset;
        margin-bottom: 12px;
    }

    .testimonial-section,
    .missed-section {
        padding-top: 22px;
    }

    .missed-card-box {
        height: unset;
    }

    .robot-img {
        width: 35%;
    }

    .inner-help-box {
        padding: 15px 112px 15px 12px;
    }

    .main-heading {
        font-size: 37.706px;
    }

    .businesses-section {
        padding: 60px 0;
    }

    .main-subheading {
        font-size: 17px;
        line-height: 26px;
    }

    .missed-card-box img {
        width: 122px;
    }

    .ready-box {
        padding: 30px;
    }

    .left-industry-img {
        margin-bottom: 22px;
        text-align: center;
    }

    .tab-content {
        padding: 12px 0;
    }

    .industry-section {
        padding: 42px 0;
    }

    .main-heading {
        font-size: 26.706px;
    }

    .feature-section-home {
        padding: 42px 0 0 0;
    }

    .testimonial-section {
        padding-top: 72px;
    }

    .btm-footer-content ul {
        justify-content: center;
        margin-top: 8px;
    }

    .btm-footer-content {
        text-align: center;
    }

    .btm-footer-content ul li a {
        margin-left: 0;
        margin-right: 22px;
    }

    .footer-bottom {
        padding: 7px 0;
    }

    .number-faq {
        width: 72px;
        margin-left: 0 !important;
    }
}

@media (max-width: 575px) {
    .help-box {
        padding: 52px 20px 52px 20px;
    }

    .testimonial-section {
        border-radius: 102px 102px 0 0;
    }

    .help-box h2 {
        line-height: normal;
    }

    .faq-section {
        padding: 90px 0 12px 0;
    }

    .how-work-content h3 {
        font-size: 26px;
        line-height: 61px;
        margin-bottom: 12px;
    }

    .footer-bottom {
        text-align: center;
    }

    .main-heading,
    .how-work-content h3 {
        line-height: normal;
    }

    .missed-card-box h3 {
        margin-left: 0;
        padding: 0;
        margin-bottom: 12px !important;
    }

    .hero-content-section h1 {
        font-size: 25px;
    }

    .btm-footer-content ul {
        justify-content: center;
    }

    .hero-content-section h1 {
        text-align: center;
    }

    .hero-content-section {
        margin: 0 0 14px 0;
        text-align: center;
    }

    .robot-img {
        width: 35%;
        right: 30%;
    }

}

@media (max-width: 480px) {
    .phone-number-text .react-tel-input .form-control {
        width: inherit;
    }

    .top-header p {
        font-size: 12px;
        line-height: 18px;
    }

    header .navbar-brand img {
        width: 100px;
    }

    header .navbar-light .navbar-toggler img {
        width: 19px;
    }

    .outer-testimonial-box .swiper {
        padding-bottom: 0;
    }

    .outer-testimonial-box .swiper-button-next,
    .outer-testimonial-box .swiper-button-prev {
        display: none;
    }

    .footer-link {
        margin-top: 17px;
    }

    .quote-box {
        width: 43px;
    }

    .hero-section {
        height: unset;
        padding: 32px 0;
    }

    .hero-content-section .get-started-btn {
        margin-top: 7px;
        font-size: 13px;
        width: 80%;
        margin-left: 0 !important;
    }

    .hero-content-section h5 {
        margin-bottom: 12px;
    }

    .help-box {
        padding: 12px;
    }

    .help-box h2 {
        font-size: 18px;
        letter-spacing: unset;
    }

    .help-box p {
        font-size: 13px;
        line-height: 22px;
    }

    .help-box {
        margin-top: 0;
    }

    .main-heading,
    .how-work-content h3 {
        font-size: 23px;
    }

    .how-works-box {
        margin-bottom: 8px;
    }

    .testimonial-section {
        border-radius: 42px 42px 0 0;
    }

    .faq-section .accordion-button {
        font-size: 17px;
    }

    .ready-box .main-heading {
        font-size: 20px;
    }

    .faq-section .accordion-body {
        font-size: 15px;
    }

    .background-differ.row {
        padding: 15px;
        margin: 4px;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 1;
        transform: unset;
    }

    .businesses-logo {
        padding: 21px 50px;
    }

    .inner-help-box {
        padding: 15px 72px 15px 12px;
    }

    header .navbar-light .navbar-nav .nav-link {
        font-size: 13px;
    }

    .businesses-section .main-heading {
        margin-bottom: 12px;
    }

    .hero-content-section h1 {
        font-size: 20px;
    }

    .hero-content-section h1 {
        font-size: 20px;
    }
}

@media (max-width: 320px) {}